/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {


                // https://css-tricks.com/snippets/jquery/done-resizing-event/
                // resize throttling/debouncing

                var resizeTimer;

                $(window).on('resize', function(e) {

                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(function() {

                        // Run code here, resizing has "stopped"

                        //jQuery waypoints doesn't autoresize
                        var header_height = $('.header').height();
                        // console.log('header_height :' + header_height);
                        $('.sticky-wrapper').height(header_height);

                    }, 250);

                });


                // JavaScript to be fired on all pages
                var header_element_to_stick = '.header';
                // var window_width = $(window).width();
                // if(window_width < 600){
                var sticky = new Waypoint.Sticky({
                    element: $(header_element_to_stick)[0]
                });


                // Allows back to top link to be placed anywhere
                $("#back-to-top-btn").click(function(event) {
                    event.preventDefault();
                    // console.log("BUTTON CLICKED");
                    $(window).scrollTo(0, 800);
                });

                $('#header-menu-nav').click(function(event) {
                    event.preventDefault();
                    $('.nav').slideToggle();
                    // console.log('menu button clicked');
                });


                // // every page has the form on no?
                $('#property-search-form').on('change', 'select', function() {
                    // console.log('#property-search-form changed');
                    load_searchform_qs();
                });
                $('.property-search-button').click(function(event) {
                    event.preventDefault();
                    console.log('ps button clicked');
                    load_searchform_qs();
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        'page_template_template_home': {
            init: function() {
                // console.log('template home js fired');

                // DG - fancy code so no jump on page load!
                var owl = $('.owl-carousel');
                owl.on('initialized.owl.carousel', function(event) {
                    // console.log('owl initialized');
                    $('.hp-page-banner-text__inner').removeClass('hidden');
                });
                owl.owlCarousel({
                    loop: true,
                    nav: false,
                    dots: false,
                    items: 1,
                    autoplay: true,
                    autoplayTimeout: 4000,
                    autoplaySpeed: 1000,
                    callbacks: true,
                    // autoplayHoverPause: true,
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'page_template_template_tertiary': {
            init: function() {
                // console.log('template tertiary js fired');


                // DG - http://stackoverflow.com/questions/24768772/prevent-a-google-maps-iframe-from-capturing-the-mouses-scrolling-wheel-behavior
                // stop gmaps stealing mouse until click

                $('#map-canvas').addClass('scrolloff'); // set the pointer events to none on doc ready
                $('.map-wrapper').on('click', function() {
                    $('#map-canvas').removeClass('scrolloff'); // set the pointer events true on click
                });

                // you want to disable pointer events when the mouse leave the canvas area;
                $("#map-canvas").mouseleave(function() {
                    $('#map-canvas').addClass('scrolloff'); // set the pointer events to none when mouse leaves the map area
                });


                var loc1lat = $("#loc1lat").text();
                var loc1lng = $("#loc1lng").text();
                var map;
                var locations = [
                    ['Location 1', loc1lat, loc1lng],
                ];

                function initialize() {
                    var mapOptions = {
                        center: {
                            lat: parseFloat(loc1lat),
                            lng: parseFloat(loc1lng)
                                //   lat: 51.513413,
                                // lng: -0.126
                        },
                        // 51.513413,-0.126
                        zoom: 12
                    };
                    map = new google.maps.Map(document.getElementById('map-canvas'),
                        mapOptions);

                    for (i = 0; i < locations.length; i++) {
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                            title: locations[i][0],
                            map: map
                        });
                    }



                }
                google.maps.event.addDomListener(window, 'load', initialize);

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'single_unb_property': {
            init: function() {
                // console.log('single-wilmax-property js fired');


                var owl = $('.owl-carousel');
                owl.owlCarousel({
                    loop: true,
                    nav: false,
                    dots: false,
                    items: 1,
                    autoplay: true,
                    autoplayTimeout: 4000,
                    autoplaySpeed: 1000,
                    callbacks: true,
                    autoplayHoverPause: true,
                });

                $('#map-canvas').addClass('scrolloff'); // set the pointer events to none on doc ready
                $('.property-single__map-wrapper').on('click', function() {
                    $('#map-canvas').removeClass('scrolloff'); // set the pointer events true on click
                });

                // you want to disable pointer events when the mouse leave the canvas area;
                $("#map-canvas").mouseleave(function() {
                    $('#map-canvas').addClass('scrolloff'); // set the pointer events to none when mouse leaves the map area
                });


                var loc1lat = $("#loc1lat").text();
                var loc1lng = $("#loc1lng").text();
                var map;
                var locations = [
                    ['Location 1', loc1lat, loc1lng],
                ];

                function initialize() {
                    var mapOptions = {
                        center: {
                            lat: parseFloat(loc1lat),
                            lng: parseFloat(loc1lng)
                                //   lat: 51.513413,
                                // lng: -0.126
                        },
                        // 51.513413,-0.126
                        zoom: 12
                    };
                    map = new google.maps.Map(document.getElementById('map-canvas'),
                        mapOptions);

                    for (i = 0; i < locations.length; i++) {
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                            title: locations[i][0],
                            map: map
                        });
                    }
                }
                google.maps.event.addDomListener(window, 'load', initialize);

            },

            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'post_type_archive_unb_property': {
            init: function() {
                // console.log('archive_unb_property fired');

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    function load_searchform_qs() {
        var url = sage_ajax_object.property_archive_url;
        args = {};

        args.tenure = $('#tenureType').val();
        args.propertytype = $('#propertyType').val();
        args.location = $('#locationType').val();
        args.propertysize = $('#propertySize').val();
        args.sortbyprice = $('#sortByPrice').val();

        url += '?';
        $.each(args, function(name, value) {
            url += name + '=' + value + '&';
        });

        // remove last &
        url = url.slice(0, -1);

        // console.log(url);

        window.location.replace(url);
    }

})(jQuery); // Fully reference jQuery after this point.
